<template>
  <div class="welcome-view">
    <div
      v-for="{ value, source, btnLabel, title, clickHandler } in covers"
      :key="value"
      class="welcome-view_cover"
      :style="{ backgroundImage: `url(${source})` }"
    >
      <div class="title">
        <div v-html="$t(title)"></div>
      </div>
      <button
        class="btn btn--secondary"
        v-html="$t(btnLabel).toUpperCase()"
        @click="clickHandler"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
// import { GENDER, CATEGORY } from "../router";
import { getMachineInstance } from "../services/machine/MachineInterfaceClass";
import rightImage from "../assets/images/right.jpg";
import leftImage from "../assets/images/left.jpg";

import queryStringMixin from "../mixins/queryStringMixin";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
// import { WIZARD_TYPE } from '../settings/enums';
export default {
  computed: {
    covers() {
      return [
        {
          value: "try-and-smell",
          source: leftImage,
          btnLabel: "try_and_smell",
          title: "discover_the_fragrances",
          clickHandler: this.onTryAndSmell,
        },
        {
          value: "start",
          source: rightImage,
          btnLabel: "start",
          title: "welcome_title",
          clickHandler: this.onStart,
        },
      ];
    },
  },
  mixins: [queryStringMixin],
  methods: {
    ...mapActions(["startTimer"]),
    onStart() {
      this.startTimer();
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
        type: "OPTION_SELECTED",
        data: { option: "PROFILER" },
      });
      getMachineInstance().initSession();
      // this.$router.push({ name: GENDER, query:{ w:WIZARD_TYPE.QUIZ} });
      this.$emit("quiz");
    },
    onTryAndSmell() {
      this.startTimer();
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
        type: "OPTION_SELECTED",
        data: { option: "AIR_PARFUM" },
      });
      getMachineInstance().initSession();
      this.$emit("catalogue");
      // const query = {
      //   w: WIZARD_TYPE.CATALOGUE
      // }
      // if (this.backoffice && this.backoffice.pacollection) {
      //   this.$router.push({
      //     name: CATEGORY,
      //     params: {
      //       category: 'pacollection'
      //     },
      //     query
      //   });
      // } else {
      //   this.$router.push({ name: GENDER,query });

      // }
    },
    mounted() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.VIEW_OPENED, {
        type: "LANDING_PAGE",
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.welcome-view
  display: flex
  height: 100vw
  width: 100%

  &_cover
    background-size: cover
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 3vw 2.5vw
    width: 50%

  .welcome-view_cover
    position: relative

    &:before
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 150%)
      content: ""
      display: block
      height: 100%
      left: 0
      position: absolute
      top: 0
      width: 100%

  .title
    m-font("DIN Engschrift Std", 400)
    m-font-size(65, 70)
    color: $secondary-theme-color
    margin-bottom: vh(30px)
    position: relative
    text-transform: uppercase

  .btn
    m-font-size(12, 12)
    m-letter-spacing(60)
    align-self: flex-start
    min-width: vw(135px)
    padding: vw(20px)
    position: relative
</style>