<template>
  <div class="lang-selector" role="listbox" v-click-outside="close">
    <div v-if="selectedLabel" class="label selected" @click="toggleOpen">
      <div class="line" />
      {{ selectedLabel }}
    </div>
    <transition name="fade">
      <div class="options" v-if="!hide">
        <div
          class="label"
          @click="() => handleClick(lang.id)"
          v-for="lang in filteredLangs"
          :key="lang.id"
        >
          {{ lang.name }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import getSalepointMixin from "../mixins/getSalepointMixin";
import { mapActions } from "vuex";
import { hardcodeLang } from "../i18n"; // TODO REMOVE WHEN ID NAME WILL BE IMPLEMENTED IN BACK

export default {
  data() {
    return {
      hide: true,
    };
  },
  mixins: [getSalepointMixin],
  methods: {
    ...mapActions(["startTimer"]),
    close() {
      this.hide = true;
    },
    open() {
      this.hide = false;
    },
    handleClick(id) {
      this.startTimer();
      this.$i18n.locale = id;
      this.eventTracker.emitEvent("LANGUAGE_CHANGED", { lang: id });
      this.$router.push({
        params: { ...this.$route.params, locale: id },
        query: this.$route.query,
      });
      this.close();
    },
    toggleOpen() {
      this.hide = !this.hide;
    },
  },
  computed: {
    selectedLang() {
      return this.$route.params.locale;
    },
    selectedLabel() {
      const lang = this.langInternal.find((e) => e.id === this.selectedLang);
      return lang ? lang.name : "";
    },
    langInternal() {
      return this.langs.map((e) => {
        const langObj = hardcodeLang.find((l) => l.id === e);
        return langObj ?? { name: e, id: e };
      });
    },
    filteredLangs() {
      return this.langInternal.filter(
        (option) => option.id !== this.selectedLang
      );
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="stylus" scoped>
.lang-selector
  m-font-size(16)
  align-items: flex-start
  cursor: pointer
  display: flex
  flex-direction: column
  left: 2.5vw
  position: absolute
  top: vw(38px)
  transition: opacity 1s
  width: vw(200px)
  z-index: 1

  .mobile &
    width: vw(40px)

  .selected
    align-items: center
    display: flex

  .line
    background-color: $secondary-btn-color
    height: 2px
    margin-right: 10px
    width: vw(50px)

  .label
    color: $secondary-btn-color
    margin-bottom: vh(5px)
    padding: vw(5px)
    position: relative
    text-align: right

  .options
    background-color: rgba(#fff, 0.75)

    &.fade-enter-active,
    &.fade-leave-active
      transition: all 0.2s ease-in-out

    &.fade-enter
      opacity: 0
      transform: translate(0, -10px)

    &.fade-leave-to
      opacity: 0
      transform: translate(0, -10px)

    .label
      color: #000
      opacity: 0.5

      &:hover
        opacity: 1
</style>