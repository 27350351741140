<template>
  <div class="swiper-container" @click="(e) => clickArrow(e)">
    <div v-if="!swiping" ref="mouse" class="mouse">
      <div class="arrow" />
    </div>
    <transition name="bottom-to-top">
      <swiper
        v-if="elements.length"
        class="swiper-carousel carousel btn-wrapper"
        ref="mySwiper"
        :options="swiperOptionsComputed"
        @slide-change-transition-start="onSwiper"
      >
        <swiper-slide
          class="swiper-slide slide"
          v-for="element in elements"
          :key="element.value"
        >
          <div
            class="img-wrapper swiper-lazy img"
            role="button"
            :style="{ backgroundImage: `url(${element.source})` }"
          />
          <div class="swiper-button-contaier">
            <swiper-button
              class="button"
              :value="element.value"
              :class="{ selected: isFieldSelected(element.value) }"
              :style="{
                backgroundColor: isFieldSelected(element.value)
                  ? buttonColor
                  : null,
              }"
            >
              {{ element.title }}
            </swiper-button>
          </div>
        </swiper-slide>
      </swiper>
    </transition>
    <transition name="bottom-to-top">
      <pagination
        v-if="elements.length && elements.length<10"
        class="pagination"
        :total="elements.length"
        :current="current"
      ></pagination>
    </transition>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

import Size from "../../mixins/size";
import SwiperButton from "../SwiperButton.vue";
import Pagination from "./Pagination.vue";
export default {
  directives: {
    swiper: directive,
  },
  mixins: [Size],
  components: {
    Swiper,
    SwiperSlide,
    SwiperButton,
    Pagination,
  },
  props: {
    elements: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    swiping: {
      type: Boolean,
    },
    focus: {
      type: Boolean,
    },
    multi: {
      type: Boolean,
    },
    buttonColor: {
      type: String,
    },
  },
  mounted() {
    this.resetListener();
  },
  unmounted() {
    this.removeListener();
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        //spaceBetween: 5,
        //spaceBetween: 150,
        simulateTouch: false,
        centeredSlides: true,
        //rewind:true,
        loopedSlides: 2,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
      current: 0,
      //left: false,
    };
  },
  computed: {
    elementsFiltered() {
      return this.elements.slice(0, 5);
    },
    swiperOptionsComputed() {
      return {
        ...this.swiperOptions,
        // loopedSlides: this.elements.length,
      };
    },
  },
  methods: {
    onSwiper(swiper) {
      this.current = swiper.realIndex;
    },
    resetListener() {
      this.removeListener();
      this.createListener();
    },
    createListener() {
      const header = this.$el
        .closest("section")
        .getElementsByClassName("header")[0];
      const height = header.clientHeight;
      this.listener = window.addEventListener("mousemove", (event) => {
        if (!this.$refs.mouse) return;
        this.$refs.mouse.style.left = event.clientX + "px";
        this.$refs.mouse.style.top = event.clientY - height + "px";
        if (event.target.tagName === "BUTTON")
          this.$refs.mouse.classList.add("hide");
        else this.$refs.mouse.classList.remove("hide");

        if (window.innerWidth / 2 > event.clientX) {
          this.left = false;
          this.$refs.mouse.classList.remove("left");
        } else {
          this.left = true;
          this.$refs.mouse.classList.add("left");
        }
      });
      this.listenerEnter = window.addEventListener("mouseover", () => {
        if (!this.$refs.mouse) return;
        this.$refs.mouse.classList.remove("hide");
      });
      this.listenerLeave = window.addEventListener("mouseout", () => {
        if (!this.$refs.mouse) return;
        this.$refs.mouse.classList.add("hide");
      });
    },
    removeListener() {
      window.removeEventListener("mousemove", this.listener);
      window.removeEventListener("mouseover", this.listenerEnter);
      window.removeEventListener("mouseout", this.listenerLeave);
    },
    isFieldSelected(value) {
      return this.selected.some((e) => e.toString() === value.toString());
    },
    handleNext(value) {
      if (!this.multi && this.clicking) return;
      this.clicking = true;
      setTimeout(() => {
        this.$emit("next", { value });
        this.clicking = false;
      }, 500);
    },
    clickArrow(event) {
      // Duplicated slide cant emit the event handleNext
      if (event.target.tagName === "BUTTON") {
        return this.handleNext(Number(event.target.getAttribute("value")));
      }
      if (!this.left) {
        var length = this.elements.length;
        var mod = (n) => ((n % length) + length) % length;
        this.current = mod(this.current - 1);
        //this.$refs.mySwiper.$el.swiper.slideTo(this.current)
        this.$refs.mySwiper.$el.swiper.slidePrev(1000, true);
      } else {
        this.current = (this.current + 1) % this.elements.length;
        this.$refs.mySwiper.$el.swiper.slideNext(1000, true);
      }
    },
  },
  watch: {
    size() {
      this.resetListener();
    },
    focus() {
      if (this.focus) this.resetListener();
      else this.removeListener();
      this.current = 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
.swiper-container
  height: 100%
  position: relative

  .pagination
    bottom: 15%
    left: 50%
    position: absolute
    transform: translate(-50%, -50%)

    &.bottom-to-top-enter
      transform: translate(-50%, 100vh)

  .bottom-to-top-enter-active,
  .bottom-to-top-leave-active
    transition: all $delay_1 $transition-timing-function
    transition-delay: $delay_1

  .bottom-to-top-enter
    transform: translateY(100vh)

  .mouse
    background-color: #fff
    border-radius: 100%
    height: vw(70px)
    left: 0
    opacity: 1
    pointer-events: none
    position: absolute
    top: 0
    transform: translate(-50%, -50%)
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out
    width: vw(70px)
    z-index: 100

    &.hide
      opacity: 0
      transform: scale(0.5) translate(-50%, -50%)
      transform-origin: center

    &.left
      .arrow
        transform: rotate(180deg)

    .arrow
      m-icon("arrow-back", 20)
      height: 100%
      margin: auto
      transform: rotate(0)
      transition: transform 0.5s ease-in-out

.swiper-carousel
  display: flex
  height: 100%
  position: relative
  transition-delay: 0

  .swiper-slide
    cursor: none
    height: 100%
    width: unset
    width: 30%

    .slide
      height: 100%
      position: relative

    .swiper-button-contaier
      align-items: center
      display: flex
      justify-content: center
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      width: 90%
      z-index: 10

    .button
      m-font-size(20px)
      background-color: #fff
      border-radius: 4px
      color: #000
      font-weight: 400
      padding: vw(19px) vh(24px)
      text-transform: uppercase

    .img-wrapper
      background-position: center center
      background-repeat: no-repeat
      background-size: cover
      height: 100%
      width: 100%
</style>