<template>
  <button :value="value" class="swiper-component-btn" @click="onClick">
    <slot>
      {{ $t("next") }}
    </slot>
  </button>
</template>
<script>
import { mapActions } from "vuex";
var globalClicked = false;
export default {
  data: () => ({
    clicked: false,
  }),
  props: ["value"],
  methods: {
    ...mapActions(["startTimer"]),
    onClick(event) {
      if (globalClicked) return;
      this.startTimer();
      this.clicked = true;
      globalClicked = true;
      setTimeout(() => {
        this.$emit("click", event);
        this.clicked = false;
        globalClicked = false;
      }, 100);
    },
  },
};
</script>
<style lang="stylus" scoped>
.swiper-component-btn
  transition: background-color 0.2s ease-in-out

  &:focus
    animation: btn-anim 0.5s

@keyframes btn-anim
  0%
    transform: scale(1)

  50%
    transform: scale(0.85)

  100%
    transform: scale(1)
</style>