<template>
  <transition-group name="list" tag="div" class="swiper-wrapper">
    <div
      class="slide no-carousel"
      v-for="element in elements"
      :key="element.id || element.value"
    >
      <div
        class="img-wrapper swiper-lazy img"
        role="button"
        :style="{ backgroundImage: `url(${element.source})` }"
      />
      <div class="swiper-button-contaier">
        <swiper-button
          :class="['button', { selected: isFieldSelected(element.value) }]"
          :style="{
            backgroundColor: isFieldSelected(element.value)
              ? buttonColor
              : null,
          }"
          @click="handleNext(element.value)"
        >
          {{ element.title }}
        </swiper-button>
      </div>
    </div>
  </transition-group>
</template>

<script>
import SwiperButton from "../SwiperButton.vue";
export default {
  props: {
    elements: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    buttonColor: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
  },
  data() {
    return {
      cicked: false,
    };
  },
  methods: {
    isFieldSelected(value) {
      if (Array.isArray(value))
        return value.every((v) => this.selected.indexOf(v) > -1);
      return this.selected.some((e) => e === value);
    },
    handleNext(value) {
      if (!this.clicked) {
        this.$emit("next", { value });
        this.clicked = true;
      }
    },
  },
  components: { SwiperButton },
  watch: {
    focus() {
      this.clicked = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.swiper-wrapper
  :nth-child(3n+1)
    transition-delay: $delay_1 !important

  :nth-child(3n+2)
    transition-delay: $delay_2 !important

  :nth-child(3n+3)
    transition-delay: $delay_3 !important

  .list-enter-active
    transition: all $delay_1 $transition-timing-function

  .list-leave-active
    transition-delay: 0 !important

  .list-enter
    transform: translateY(100%)

  .slide
    height: 100%
    position: relative

    &.no-carousel
      flex: 1 1 100%

    .swiper-button-contaier
      align-items: center
      display: flex
      justify-content: center
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      width: 90%
      z-index: 10

    .button
      m-font("DIN Engschrift Std")
      m-font-size(35, 35)
      background-color: #fff
      border-radius: 4px
      color: #000
      padding: vw(12px) vw(16px)
      text-transform: uppercase
      transition: transform 0.2s ease-in-out
      transition-delay: 0s !important

    .img-wrapper
      background-position: center center
      background-repeat: no-repeat
      background-size: cover
      height: 100%
      width: 100%

.swiper
  display: flex
  height: 100%

.swiper-slide
  width: unset
</style>