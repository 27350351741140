<template>
  <div
    v-if="!isMobile"
    class="next-component-btn"
    :class="{ anim: clicked }"
    @click="onClick"
  >
    <div class="next-text">
      <slot>
        {{ $t("next") }}
      </slot>
    </div>
  </div>
  <div
    v-else
    class="next-component-btn"
    :class="{ anim: clicked }"
    @click="onClick"
  >
    <slot>
      {{ $t("next") }}
    </slot>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    clicked: false,
  }),
  methods: {
    ...mapActions(["startTimer"]),
    onClick(event) {
      this.startTimer();
      this.clicked = true;
      setTimeout(() => {
        this.$emit("click", event);
      }, 1500);
    },
  },
};
</script>
<style lang="stylus" scoped>
.next-component-btn
  m-font-size(12, 14)
  align-items: center
  background-color: #000
  border-radius: 5px
  color: #fff
  cursor: pointer
  display: flex
  height: vw(50px)
  justify-content: center
  overflow: hidden
  padding-left: vw(20px)
  padding-right: vw(20px)
  position: relative
  text-align: center
  text-transform: uppercase
  z-index: 1

  .mobile &
    height: vw(35px)

  &.anim
    animation: btn-anim 0.5s

    .next-text
      animation: text-anim 1s
      animation-delay: 0.5s

@keyframes btn-anim
  0%
    transform: scale(1)

  50%
    transform: scale(0.85)

  100%
    transform: scale(1)

.next-text
  m-font("Paco")
  m-font-size(12, 14)
  m-letter-spacing(100)

@keyframes text-anim
  0%
    opacity: 1
    transform: translateY(0)

  25%
    opacity: 0
    transform: translateY(-200%)

  50%
    opacity: 0
    transform: translateY(200%)

  100%
    opacity: 1
    transform: translateY(0)
</style>