<template>
  <div ref="wizard" class="wizard-paco-rabanne wizard_v2">
    <transition name="fade">
      <lang-selector class="lang-selector" v-if="!isProfiler && current===0" />
    </transition>
    <swiper
      ref="swiperSection"
      class="swiper-wrapper"
      :options="swiperSection"
      @slide-change-transition-end="swiping = false"
      @slide-change-transition-start="onSwiper"
    >
      <section class="step swiper-slide step-0">
        <salepoint-welcome-view
          v-if="!isProfiler"
          @quiz="() => startSalepoint(true)"
          @catalogue="() => startSalepoint(false)"
        />
        <div v-else class="wrapper">
          <div class="container">
            <h1>{{ $t("welcome_title") }}</h1>
            <h2 v-html="$t('welcome_subtitle_1')"></h2>
            <button :class="{ started }" @click="handleNext()">start</button>
          </div>
        </div>
      </section>
      <section
        v-for="(section, index) in sections"
        :id="section.value"
        :key="section.value"
        class="step swiper-slide"
        :class="[
          { ['not-show']: current < index + 1 || isLoading(section) },
          section.value,
          sectionClass(index),
        ]"
        :style="{ backgroundColor: getSectionColor(section, index) }"
      >
        <div class="header" :class="{ validate: showValidateBtn(section) }">
          <div class="previous" @click="handleBack">
            {{ $t("wizard_previous") }}
          </div>
          <div class="title">
            <div v-if="!section.hideNumber" class="after">
              {{ index + 1 }}/{{ sections.length }}
            </div>
            <div class="title-wrapper">
              <transition name="main-fade">
                <div class="main">
                  {{ section.title }}
                </div>
              </transition>
              <transition name="headersubtitle" mode="out-in">
                <div class="subtitle" v-if="getFieldTitles(section)">
                  {{ getFieldTitles(section) }}
                </div>
                <div class="subtitle" v-else-if="section.subtitle">
                  {{ section.subtitle }}
                </div>
              </transition>
            </div>
            <div v-if="!section.hideNumber" class="before">
              {{ index + 1 }}/{{ sections.length }}
            </div>
          </div>
          <transition name="fade">
            <next-button v-if="showValidateBtn(section)" @click="handleNext">
              {{ $t("wizard_validate") }}
            </next-button>
          </transition>
        </div>
        <div class="body">
          <component
            :swiping="swiping"
            :multi="section.multi"
            :focus="current === index + 1"
            :is="getComponent(section)"
            :elements="section.elements"
            :selected="getSselected(section)"
            @next="handleNext(section, $event)"
            :button-color="getSectionColor(section, index)"
            :is-array="!!section.array"
          ></component>
        </div>
      </section>
    </swiper>
  </div>
</template>

<script>
import { Swiper, directive } from "vue-awesome-swiper";
import womanImage from "../assets/images/woman.jpg";
import manImage from "../assets/images/man.png";
import pacocollectionImage from "../assets/images/pacollection.jpg";

import ingredientManImage from "../assets/images/man/ingredients.jpg";
import attitudeManImage from "../assets/images/man/attitude.jpg";

import ingredientWomanImage from "../assets/images/woman/ingredients.jpg";
import attitudeWomanImage from "../assets/images/woman/attitude.jpg";

import ingredientPacollectionImage from "../assets/images/pacollection/ingredients.jpg";
import attitudePacollectionImage from "../assets/images/pacollection/attitude.jpg";

import "swiper/swiper-bundle.css";
import queryStringMixin from "../mixins/queryStringMixin";
import noCarouselVue from "../components/swipers/noCarousel.vue";
import CarouselVue from "../components/swipers/Carousel.vue";
import MobileCarouselVue from "../components/swipers/MobileCarousel.vue";
import NextButton from "../components/NextButton.vue";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
import getSalepointMixin from "../mixins/getSalepointMixin";
import SalepointWelcomeView from "./SalepointWelcomeView.vue";
import { CATEGORY, RECOMMENDATION } from "../router";
import { i18n } from "../main";
import { mapGetters } from "vuex";
import LangSelector from '../components/LangSelector.vue';
/** @enum {string} */
const FIND_BY_ENUM = {
  INGREDIENTS: "ing",
  ATTITUDE: "att",
};
export default {
  components: {
    Swiper,
    NextButton,
    SalepointWelcomeView,
    LangSelector,
  },
  mixins: [queryStringMixin, getSalepointMixin],
  directives: {
    swiper: directive,
  },
  fetch: {
    genders: {
      model: "genders",
      params() {
        return this.backoffice ? [this.backoffice.id] : null;
      },
    },
    familiesFeched: {
      model: "families",
      method: "get",
      params() {
        return this.backoffice ? [this.gender, this.backoffice.id] : null;
      },
    },
    intensitiesFeched: {
      model: "intensities",
    },
    steps: {
      model: "steps",
    },
    conceptsFeched: {
      model: "concepts",
    },
    ingredientsFeched: {
      model: "ingredients",
      params() {
        return this.backoffice
          ? [this.gender, this.backoffice.id, this.families]
          : null;
      },
    },
    perfumeCategoriesFetched: {
      model: "perfumeCategory",
      method: "get",
      params() {
        return this.backoffice ? [this.backoffice.id, this.gender] : null;
      },
    },
    // with mixin not working properly. it isn't reactive
    backoffice: {
      model() {
        return isNaN(this.$route.params.catalogue) ? "profiler" : "salepoint";
      },
      method: "getById",
      params() {
        return this.$route.params.catalogue
          ? [this.$route.params.catalogue]
          : null;
      },
    },
  },
  data() {
    return {
      resultingProfile: {
        attitudes: [],
        categories: [],
      },
      swiperSection: {
        slidesPerView: "auto",
        //spaceBetween: 5,
        //slidesPerView: 2,
        //spaceBetween: 150,
        centeredSlides: true,
        simulateTouch: false,
        allowTouchMove: false,
        speed: 1000,
        direction: "vertical",
        // cssMode:true,
        mousewheel: false,
        keyboard: false,
        // Some Swiper option/callback...
      },
      current: 0,
      swiping: false,
      quiz: true,
      // gender:null,
      // findBy:null
    };
  },
  asyncComputed: {},
  computed: {
    ...mapGetters(["lang"]),
    started() {
      return this.current !== 0;
    },
    currentSection() {
      const index = this.current - 1;
      return this.sectionValues[index];
    },
    perfumeCategories() {
      // Remove fake Image
      const fakeImage = [attitudeWomanImage];
      // patch for remove elements when this sections not focused
      return this.perfumeCategoriesFetched.map((category, i) => ({
        value: category.id.toString(),
        key: category.name,
        source:
          category.imageUrl ||
          fakeImage.find((e, index) => index === i % fakeImage.length), //TODO: replace with category.imageUrl when it is not null in the category object
        alt: category.name,
        title: category.name,
      }));
    },
    gendersSwiper() {
      return [
        {
          value: "F",
          source: womanImage,
          key: "F",
          alt: "Female",
          title: this.$t("gender_woman"),
        },
        {
          value: "M",
          key: "M",
          source: manImage,
          alt: "Male",
          title: this.$t("gender_man"),
        },
        {
          value: "U",
          key: "U",
          source: pacocollectionImage,
          alt: "unisex",
          title: this.$t("gender_pacollection"),
        },
      ].filter((e) => this.genders?.includes(e.value));
    },
    findBySwiper() {
      return [
        {
          value: FIND_BY_ENUM.INGREDIENTS,
          source: this.isMan
            ? ingredientManImage
            : this.isWoman
            ? ingredientWomanImage
            : ingredientPacollectionImage,
          alt: "Ingredients",
          key: "ingredients",
          title: this.$t("option_ingredients"),
        },
        {
          value: FIND_BY_ENUM.ATTITUDE,
          source: this.isMan
            ? attitudeManImage
            : this.isWoman
            ? attitudeWomanImage
            : attitudePacollectionImage,
          alt: "Attitude",
          key: "attitude",
          title: this.$t("option_attitude"),
        },
      ];
    },
    aptitudeMale() {
      return this.getDataFromFamilies("pacoAttitude", "male");
    },
    aptitudeFemale() {
      return this.getDataFromFamilies("pacoAttitude", "female");
    },
    aptitudeUnisex() {
      return this.getDataFromFamilies("pacoAttitude", "unisex");
    },
    attitudes() {
      return this.isMan
        ? this.aptitudeMale
        : this.isWoman
        ? this.aptitudeFemale
        : this.aptitudeUnisex;
    },
    attitudeSwiper() {
      const sliceMax = this.isMan ? 3 : 4;
      return this.attitudes.slice(0, sliceMax).map((family) => ({
        id: family.ids.join("-"),
        value: family.ids,
        alt: family.name,
        key: family.name,
        title: family.name,
        source: family.imageUrl, // TODO add lightImageUrl
      }));
    },
    categoryMale() {
      return this.getDataFromFamilies("pacoCategory", "male");
    },
    categoryFemale() {
      return this.getDataFromFamilies("pacoCategory", "female");
    },
    categoryUnisex() {
      return this.getDataFromFamilies("pacoCategory", "unisex");
    },
    categories() {
      return this.isMan
        ? this.categoryMale
        : this.isWoman
        ? this.categoryFemale
        : this.categoryUnisex;
    },
    categoriesSwiper() {
      const sliceMax = this.isMan ? 3 : 4;
      return this.categories.slice(0, sliceMax).map((family) => ({
        id: family.ids.join("-"),
        value: family.ids,
        alt: family.name,
        key: family.name,
        title: family.name,
        source: family.imageUrl, // TODO add lightImageUrl
      }));
    },
    ingredientSwiper() {
      return this.ingredientsFeched
        .filter((e) => e.imageUrl)
        .map((ingredient) => ({
          value: ingredient.id,
          alt: ingredient.name,
          key: ingredient.name,
          title: ingredient.name,
          source: ingredient.imageUrl, // TODO add lightImageUrl
        }));
    },
    conceptSwiper() {
      return this.conceptsFeched
        .filter((e) => e.imageUrl)
        .filter(e => this.families.some(f => e.families.includes(Number(f))))
        .map((concept) => ({
          id: concept.id,
          value: concept.id,
          alt: concept.name,
          key: concept.name,
          title: concept.name,
          source: concept.imageUrl, // TODO add lightImageUrl
        }));
    },
    intensities() {
      var intensities =
        this.intensitiesFeched?.map((intensity) => {
          const title = intensity.name
          const gender = intensity.genders.find(
            (g) => g.id === (this.gender || "U")
          );
          const imageUrl = gender.image;
          const value = gender.value;
          return {
            title,
            imageUrl,
            id: intensity.id,
            value,
            alt: title,
            source: imageUrl,
            key: intensity.id,
          };
        }) ?? [];
      if (!this.isMobile) intensities.push(intensities.shift());
      return intensities;
    },
    sectionValues() {
      var sections = [];
      if (!this.isProfiler && !this.quiz)
        sections = [
          {
            value: "gender",
            title: this.$t("question_gender"),
            component: "no-carousel",
            field: "gender",
            multi: false,
            hideNumber: true,
          },
          {
            value: "catalogue",
            title: "",
            component:
              this.perfumeCategories.length > 4 ? "carousel" : "no-carousel",
            field: "catalogue",
            multi: false,
            hideNumber: true,
          },
        ];
      else
        sections = [
          {
            value: "gender",
            title: this.$t("question_gender"),
            component: "no-carousel",
            field: "gender",
            multi: false,
          },
          {
            value: "find-by",
            title: this.$t("question_option"),
            component: "no-carousel",
            field: "findBy",
            multi: false,
          },
          {
            value: "step-3",
            title:
              this.findBy === FIND_BY_ENUM.ATTITUDE
                ? this.isMan
                  ? this.$t("question_attitude_man")
                  : this.isWoman
                  ? this.$t("question_attitude_woman")
                  : this.$t("question_attitude_pacollection")
                : this.$t("question_family"),
            component: "no-carousel",
            field: "families",
            multi: false,
            array: true,
          },
          {
            value: "step-4",
            title:
              this.findBy === FIND_BY_ENUM.ATTITUDE
                ? this.$t("title_attitude_elements")
                : this.$t("question_ingredient"),
            subtitle:
              this.findBy === FIND_BY_ENUM.ATTITUDE
                ? this.$t("choose_options_instructions")
                : this.$t("choose_ingredients_instructions"),
            component: "carousel",
            field:
              this.findBy === FIND_BY_ENUM.ATTITUDE
                ? "concepts"
                : "ingredients",
            fields: ["concepts", "ingredients"],
            multi: true,
          },
          {
            value: "step-5",
            title: this.$t("question_intensity"),
            component: "carousel",
            field: "intensity",
            multi: false,
          },
        ];
      if (this.backoffice?.pacollection)
        return sections.filter((sect) => sect.value !== "gender");
      return sections;
    },
    sectionElements() {
      return {
        gender:
          this.current <= this.indexOfSection("gender")
            ? []
            : this.gendersSwiper,
        catalogue:
          this.current <= this.indexOfSection("catalogue")
            ? []
            : this.perfumeCategories,
        ["find-by"]:
          this.current <= this.indexOfSection("find-by")
            ? []
            : this.findBySwiper,
        ["step-3"]:
          this.current <= this.indexOfSection("step-3")
            ? []
            : this.findBy === FIND_BY_ENUM.ATTITUDE
            ? this.attitudeSwiper
            : this.categoriesSwiper,
        ["step-4"]:
          this.current <= this.indexOfSection("step-4")
            ? []
            : this.findBy === FIND_BY_ENUM.ATTITUDE
            ? this.conceptSwiper
            : this.ingredientSwiper,
        ["step-5"]:
          this.current <= this.indexOfSection("step-5") ? [] : this.intensities,
      };
    },
    sections() {
      const sections = this.sectionValues.map((sect) => ({
        ...sect,
        elements: this.sectionElements[sect.value],
      }));
      return sections;
    },
    qsIngredients() {
      return this.$route.query.ingredients;
    },
    qsConcepts() {
      return this.$route.query.concepts;
    },
    qsIntensity() {
      return this.$route.query.intensity;
    },
  },
  methods: {
    isSection(value) {
      return this.currentSection?.value === value;
    },
    indexOfSection(value) {
      return this.sectionValues.findIndex((sect) => sect.value === value);
    },
    startSalepoint(quiz = false) {
      this.quiz = quiz;
      if (this.backoffice && this.backoffice.pacollection) {
        this.gender = "U";
        this.next(1);
      } else this.next();
    },
    getSectionColor(section, index) {
      const step = this.steps?.find((step) => Number(step.id) === index + 1);
      return step?.genders.find(
        (confGender) => confGender.id === (this.gender ?? "U")
      )?.colour;
    },
    onSwiper(swiper) {
      this.swiping = true;
      this.current = swiper.realIndex;
      this.qsStep = swiper.realIndex;
    },

    showValidateBtn(section, n = 0) {
      return section.value === "step-4" && this.getField(section).length > n;
    },
    getFieldTitles(section) {
      if (section.value === "step-4")
        return section?.elements
          .filter((e) => this[section.field]?.includes(e.value))
          .map(
            (e) =>
              e.title.charAt(0).toUpperCase() + e.title.substr(1).toLowerCase()
          )
          .join(", ");
      return null;
    },
    isLoading(section) {
      var value = (() => {
        switch (section.value) {
          case "gender":
            return this.isGendersUpdating;
          case "find-by":
            return false;
          case "step-3":
            return this.isFamiliesFechedUpdating;
          case "step-4":
            return this.findBy === FIND_BY_ENUM.ATTITUDE
              ? this.isConceptsFechedUpdating
              : this.isIngredientsFechedUpdating;
          default:
            return false;
        }
      })();
      return value === null ? true : false;
    },
    sectionClass(index) {
      const selectedValues = this.sections.slice(0, index).map((sect) => {
        switch (sect.value) {
          case "gender":
            return this[sect.field] === "F" ? "w" : "m";
          case "find-by":
            return this[sect.field] === FIND_BY_ENUM.INGREDIENTS
              ? "families"
              : "attitude";
          case "step-3":
            return sect.field;
          default:
            return "";
        }
      });
      return `${selectedValues.join(" ")}`;
    },
    getField(section) {
      return this[section.field];
    },
    getSselected(section) {
      const value = this[section.field];
      return value ? (Array.isArray(value) ? value : [value]) : [];
    },
    getComponent(section) {
      if (this.isMobile) return MobileCarouselVue;
      switch (section.component) {
        case "no-carousel":
          return noCarouselVue;
        case "carousel":
          return CarouselVue;
        default:
          return noCarouselVue;
      }
    },
    getDataFromFamilies(field, gender) {
      return this.familiesFeched
        .filter((family) => family[field] && family[field][gender])
        .reduce((array, family) => {
          const index = array.findIndex(
            (element) => element.name === family[field][gender].name
          );
          if (index < 0)
            array.push({ ids: [family.id], ...family[field][gender] });
          else {
            array[index].ids.push(family.id);
          }
          return array;
        }, []);
    },
    isFieldSelected(field, value) {
      return Array.isArray(this[field])
        ? this[field].some((e) => e === value)
        : this[field] === value;
    },
    setArrayField(field, value) {
      /** @type {Array} */

      const array = this[field];
      let index = array?.indexOf(value);
      if (index > -1) array.splice(index, 1);
      else if (array.length < 2) array.push(value);
      else {
        array.shift();
        array.push(value);
      }
      this[field] = array;
    },
    handleAddRemoveProfile(val, prev, type) {
      let added, removed;
      const entity = type === "INGREDIENT" ? "ingredients" : "concepts";
      if (!prev) {
        added = val;
      } else {
        added = val?.filter((v) => !prev?.includes(v));
        removed = prev?.filter((v) => !val?.includes(v));
      }
      added?.map((i) => {
        this.eventTracker.emitEvent("ADD_TO_PROFILE", {
          type,
          id: parseInt(i),
          resultingProfile: {
            ...this.resultingProfile,
            [entity]: val?.map((i) => parseInt(i)) ?? [],
          },
        });
      });
      removed?.map((i) => {
        this.eventTracker.emitEvent("REMOVE_FROM_PROFILE", {
          type,
          id: i,
          resultingProfile: {
            ...this.resultingProfile,
            [entity]: val?.map((i) => parseInt(i)) ?? [],
          },
        });
      });
    },
    trackEvent(section, element) {
      switch (section.value) {
        case "gender":
          this.eventTracker.emitEvent("GENDER_CHANGED", {
            gender: element.value,
          });
          break;
        case "find-by":
          break;
        case "step-3":
          var ids = element.value;
          if (this.findBy === FIND_BY_ENUM.INGREDIENTS) {
            const category = this.getCategoryByFamilies(ids);
            this.resultingProfile.categories = [category.id];
            this.eventTracker.emitEvent("ADD_TO_PROFILE", {
              type: "CATEGORY",
              id: category.id,
              resultingProfile: { categories: [category.id] },
            });
          } else if (this.findBy === FIND_BY_ENUM.ATTITUDE) {
            const attitude = this.getAttitudeByFamilies(ids);
            this.resultingProfile.attitudes = [attitude.id];
            this.eventTracker.emitEvent("ADD_TO_PROFILE", {
              type: "ATTITUDE",
              id: attitude.id,
              resultingProfile: { attitudes: [attitude.id] },
            });
          }
          break;
      }
    },
    next(step) {
      clearTimeout(this.$timer);
      this.$timer = setTimeout(() => {
        if (step) this.current = step;
        else this.current++;
        this.$refs.swiperSection.$swiper.slideTo(this.current);
      }, 200);
    },

    handleNext(section, element) {
      const field = section?.field;
      const id = section?.value;
      if (section && element) this.trackEvent(section, element);
      if (!section && !element) {
        this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
          type: "OPTION_SELECTED",
          data: { option: "PROFILER" },
        });
      }
      switch (id) {
        case "step-4":
          this.setArrayField(field, element.value);
          break;
        case "step-5":
          this[field] = element.value;
          setTimeout(() => {
            this.$router.push({ name: RECOMMENDATION, query: this.$route.query });
          }, 500);
          break;
        case "catalogue":
          this.$router.replace({
            name: CATEGORY,
            params: {
              category: element.value,
            },
            query: {
              gender: this.gender,
            },
          });
          break;
        default:
          if (field) this[field] = element.value;
          this.next();

          break;
      }

      // document.getElementById(step).scrollIntoView();
      // this.$refs.wizard.scrollTo('#'+step)
    },
    getAttitudeByFamilies(families) {
      return this.attitudes.find((a) =>
        a.ids.every((id) => families?.includes(id))
      );
    },
    getCategoryByFamilies(families) {
      return this.categories.find((a) =>
        a.ids.every((id) => families?.includes(id))
      );
    },
    handleBack() {
      this.current--;
      this.$refs.swiperSection.$swiper.slideTo(this.current);
      // document.getElementById(step).scrollIntoView();
      // this.$refs.wizard.scrollTo('#'+step)
    },
    clearUnnecesaryData() {
      // -1 because we dont include de mainpage. but we sum 1 because we want to keep the data of the current slide
      const sections = this.sections.slice(this.current);
      sections.forEach((section) => {
        if (section.fields)
          section.fields.forEach((field) => (this[field] = null));
        else this[section.field] = null;
      });
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        const locale = to.params.locale;
        const currentLocale = this.lang;
        if (currentLocale !== locale) {
          i18n.locale = locale;
          this.$store.commit("setLanguage", locale);
          console.debug("set Lang to", locale);
        }
        const newInstallationId = this.$route.params.catalogue;
        if (!this.eventTracker.installationId) {
          this.eventTracker.installationId = newInstallationId;
        }
        if (from) {
          if (to.params.catalogue !== from.params.catalogue) {
            this.eventTracker.installationId = newInstallationId;
          }
        }
      },
      immediate: true,
    },
    qsStep() {
      if (this.qsStep === 0 && this.current !== 0)
        this.$refs.swiperSection.$swiper.slideTo(0);
    },
    qsIngredients(val, prev) {
      this.handleAddRemoveProfile(val, prev, "INGREDIENT");
    },
    qsConcepts(val, prev) {
      this.handleAddRemoveProfile(val, prev, "CONCEPT");
    },
    qsIntensity(val, prev) {
      if (!!val && val != prev) {
        const intensity = this.intensities.find(
          (i) => i.value === parseInt(val)
        ).id;
        const resultingProfile = {
          ...this.resultingProfile,
          concepts: this.qsConcepts
            ? this.qsConcepts.map((c) => parseInt(c))
            : [],
          ingredients: this.qsIngredients
            ? this.qsIngredients.map((i) => parseInt(i))
            : [],
          intensity,
        };
        this.eventTracker.emitEvent(EVENT_NAME_ENUM.ADD_TO_PROFILE, {
          type: "INTENSITY",
          id: intensity,
          resultingProfile,
        });
      }
    },
    current: {
      handler() {
        // eslint-disable-next-line
        if (process.env.VUE_APP_ELECTRON === 'true' && this.current===0 && window.electron) {
          console.debug('check if is updated')
          // eslint-disable-next-line
          electron?.isUpdated().then(bool => {
            console.log('need reoload = ', bool)
          })
        }
        //this.qsStep=this.current
        this.clearUnnecesaryData();
        // aggregate one to the first section
        const index = this.current - 1;
        const section = this.sections[index];
        if (this.current === 0) {
          this.eventTracker.emitEvent(EVENT_NAME_ENUM.VIEW_OPENED, {
            type: "LANDING_PAGE",
          });
        }
        if (!section) return;
        let eventName = (() => {
          switch (section.value) {
            case "gender":
              return "SET_GENDER";
            case "find-by":
              return "FIND_BY";
            case "step-3":
              return this.findBy === FIND_BY_ENUM.ATTITUDE
                ? "SELECT_ATTITUDE"
                : "SELECT_FAMILY";
            case "step-4":
              return this.findBy === FIND_BY_ENUM.ATTITUDE
                ? "EXPLORE_BY_CONCEPTS"
                : "EXPLORE_BY_INGREDIENTS";
            case "step-5":
              return "SELECT_INTENSITY";
            case "catalogue":
              return "COLLECTIONS";
            default:
              return null;
          }
        })();
        if (eventName)
          this.eventTracker.emitEvent("VIEW_OPENED", { type: eventName });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.wizard-paco-rabanne
  height: 100vh
  overflow: hidden
  scroll-behavior: smooth
  scroll-snap-type: y mandatory
  .lang-selector
    z-index 10

.step
  display: flex
  flex-direction: column
  height: 100vh
  position: relative

  &.not-show
    .header
      $translate-x = "calc(-50% - %s)" % vw(25px)
      transform: translate($translate-x, -50%) scale(2)

      .mobile &
        width: 50vw

      .title
        .mobile &
          width: 100%

        .before
          opacity: 1

        .after
          opacity: 0

        .subtitle
          display: none

      .previous
        opacity: 0

  &.step-0
    background-image: url("~@/assets/images/wizard.png")
    background-position: center center
    background-repeat: no-repeat
    background-size: cover

    .wrapper
      align-items: center
      background: linear-gradient(0, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))
      display: flex
      height: 100%
      justify-content: center
      width: 100%

      .container
        align-items: center
        color: #fff
        display: flex
        flex-direction: column

        h1
          m-font("DIN Engschrift Std")
          m-font-size(170, 170)
          margin-bottom: vw(30px)
          margin-top: 0
          max-width: vw(1000px)
          text-align: center
          text-transform: uppercase

          .mobile &
            m-font-size(50, 50)

        h2
          m-font-size(18, 25)
          font-weight: 500
          margin-bottom: vw(30px)
          margin-top: 0
          text-align: center

          .mobile &
            width: 90%

        button
          m-font("Paco")
          m-font-size(12, 14)
          background-color: #fff
          border-radius: 5px
          color: #000
          padding: vw(20px) vw(50px)
          transition: background-color 0.2s ease-in-out

          .mobile &
            padding: vw(15px)

          &:focus
            animation: btn-anim 0.5s

          &.started
            background-color: #fff

  &.gender
    >>> .slide
      &:first-child
        .img-wrapper
          background-position: top center

  &.step-4
    .header
      .title
        align-items: flex-start

        .after,
        .before
          margin-right: vw(10px)
          margin-top: vw(1px)

  .header
    $padding = vw(70px)
    align-items: center
    display: flex
    height: $header-height
    justify-content: space-between
    left: 50%
    position: absolute
    top: 50%
    transform: translate(-50%, -50vh)
    transition: all $delay_1 ease-in-out
    transition-delay: $delay_1
    width: "calc(100% - (%s * 2))" % $padding
    will-change: transform, width

    .mobile &
      $padding = vw(10px)
      height: $header-height-mobile
      width: "calc(100% - (%s * 2))" % $padding

    &.validate
      .after,
      .main
        .mobile &
          display: none

      .title
        .mobile &
          width: 45%

        .title-wrapper
          .mobile &
            width: 100%

        .subtitle
          .mobile &
            m-font-size(16, 20)
            m-ellipsis(100%)

    > .button
      position: relative
      width: unset
      z-index: 1

    .previous
      m-font("Paco")
      m-font-size(14, 17)
      cursor: pointer
      opacity: 1
      position: relative
      text-transform: uppercase
      transition: opacity 1s ease-in-out
      transition-delay: $delay_1
      will-change: opacity
      z-index: 1

      &:after
        background-color: #000
        content: ""
        display: block
        height: 1px
        margin-top: vw(3px)
        width: 100%

        .mobile &
          display: none

      .mobile &
        m-font-size(0)
        m-icon("go-back", 20)

    .title
      m-font-size(18, 25)
      align-items: center
      display: flex
      justify-content: center
      left: 50%
      margin: 0
      position: absolute
      text-align: center
      transform: translateX(-50%)
      width: 100%

      .mobile &
        transition: width 1s ease-in-out
        transition-delay: $delay_1
        width: 80%

      .after,
      .before
        transition: opacity 1s ease-in-out
        transition-delay: $delay_1

        .mobile &
          m-font-size(16, 20)

      .after
        margin-right: vw(10px)
        opacity: 1
        // width: vw(25px)

      .before
        $translate-y = "calc(-100% - %s)" % vw(10px)
        left: 50%
        margin-left: vw(12.5px)
        opacity: 0
        position: absolute
        top: 0
        transform: translate(-50%, $translate-y)

      .title-wrapper
        align-items: center
        display: flex

        .mobile &
          flex-direction: column

      .main
        .mobile &
          m-font-size(16, 20)

      .subtitle
        m-font-size(14, 20)
        margin-left: vw(10px)
        margin-top: vw(4px)

        .mobile &
          m-font-size(12, 16)
          margin: 0

      .headersubtitle-enter-active,
      .headersubtitle-leave-active
        transition: 0.5s ease-in

      .headersubtitle-enter
        opacity: 0
        transform: translate(0, -100%)

      .headersubtitle-leave-to
        opacity: 0
        transform: translate(0, 100%)

  .body
    flex: 1 1 0%
    padding-top: $header-height

    .mobile &
      padding-top: $header-height-mobile

    >>> .slide
      &:not(:last-child)
        border-right: 2px solid #fff

.fade-enter-active,
.fade-leave-active
  transition: 0.5s ease-in-out

.fade-enter,
.fade-leave-to
  opacity: 0

@keyframes btn-anim
  0%
    transform: scale(1)

  50%
    transform: scale(0.85)

  100%
    transform: scale(1)
</style>