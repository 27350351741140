<template>
  <div class="swiper-pagination">
    <div
      class="dot"
      v-for="id in dots"
      :key="id"
      :class="{ selected: id === current }"
    />
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    current: Number,
  },
  computed: {
    dots() {
      return Array.from(new Array(this.total)).map((_, index) => index);
    },
  },
};
</script>

<style lang="stylus" scoped>
$size = vw(5px)

.swiper-pagination
  display: flex
  gap: vw(10px)

  .dot
    background-color: #fff
    // border-radius: 50%
    height: $size
    opacity: 0.5
    transform: scale(1)
    transition: transform 0.6s cubic-bezier(0.26, 0.58, 0.65, 1), opacity 0.3s cubic-bezier(0.26, 0.58, 0.65, 1)
    width: $size

    &.selected
      opacity: 1
      transform: scale(1.5)
      transition: transform 0.6s cubic-bezier(0.26, 0.58, 0.65, 1), opacity 0.3s cubic-bezier(0.26, 0.58, 0.65, 1)
</style>