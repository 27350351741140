<template>
  <div class="swiper-container-mobile" @click="handleClickDiv">
    <transition name="bottom-to-top">
      <swiper
        v-if="elementsFiltered.length"
        class="swiper-carousel carousel btn-wrapper"
        ref="mySwiper"
        :auto-update="false"
        :auto-destroy="true"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
        :options="swiperOptions"
        @slide-change-transition-start="onSwiper"
      >
        <swiper-slide
          class="swiper-slide slide"
          v-for="element in elementsFiltered"
          :key="element.id || element.value"
        >
          <div
            class="img-wrapper swiper-lazy img"
            role="button"
            :style="{ backgroundImage: `url(${element.source})` }"
          />
          <div class="swiper-button-contaier">
            <swiper-button
              class="button"
              :value="element.id || element.value"
              :class="{ selected: isFieldSelected(element.value) }"
              :style="{
                backgroundColor: isFieldSelected(element.value)
                  ? buttonColor
                  : null,
              }"
            >
              {{ element.title }}
            </swiper-button>
          </div>
        </swiper-slide>
      </swiper>
    </transition>
    <transition name="bottom-to-top">
      <div v-if="elementsFiltered.length" class="nav-button prev" @click="prev">
        <div class="icon" />
      </div>
    </transition>
    <transition name="bottom-to-top">
      <div v-if="elementsFiltered.length" class="nav-button next" @click="next">
        <div class="icon" />
      </div>
    </transition>
    <transition name="bottom-to-top">
      <pagination
        v-if="elementsFiltered.length>0 && elementsFiltered.length<10"
        class="pagination"
        :total="elementsFiltered.length"
        :current="current"
      ></pagination>
    </transition>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Size from "../../mixins/size";
import SwiperButton from "../SwiperButton.vue";
import Pagination from "./Pagination.vue";
export default {
  directives: {
    swiper: directive,
  },
  mixins: [Size],
  components: {
    Swiper,
    SwiperSlide,
    SwiperButton,
    Pagination,
  },
  props: {
    elements: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    buttonColor: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    multi: {
      type: Boolean,
    },
    isArray: {
      type: Boolean,
    },
  },

  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        // spaceBetween: 3,
        //spaceBetween: 150,
        centeredSlides: true,
        loop: "true",
        loopedSlides: 3,
        pagination: {
            el: '.swiper-pagination'
          },
        // Some Swiper option/callback...
      },
      current: 0,
    };
  },
  computed: {
    elementsFiltered() {
      return this.elements
    },
  },
  methods: {
    onSwiper(swiper) {
      this.current = swiper.realIndex;
    },
    next() {
      this.$refs.mySwiper.$el.swiper.slideNext(500, true);
    },
    prev() {
      this.$refs.mySwiper.$el.swiper.slidePrev(500, true);
    },
    handleClickDiv(event) {
      if (!this.multi && this.clicked) return;
      // Duplicated slide cant emit the event handleNext
      if (event.target.tagName === "BUTTON") {
        var value = event.target.getAttribute("value");
        const clickedElement = this.elements.find(e => e.id == value || e.value == value)
        this.handleNext(clickedElement.value);
        this.clicked = true;
      }
    },
    isFieldSelected(value) {
      return this.selected.some((e) => e === value);
    },
    handleNext(value) {
      if (!this.multi && this.clicking) return;
      this.clicking = true;
      setTimeout(() => {
        if (this.isArray && !Array.isArray(value)) value = [value];
        this.$emit("next", { value });
        this.clicking = false;
      }, 500);
    },
  },
  watch: {
    focus() {
      if (this.focus) this.clicked = false;
      this.current = 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
.swiper-container-mobile
  height: 100%
  position: relative

  .pagination
    bottom: 15%
    left: 50%
    position: absolute
    transform: translate(-50%, -50%)

    &.bottom-to-top-enter
      transform: translate(-50%, 100vh)

  .bottom-to-top-enter-active,
  .bottom-to-top-leave-active
    transition: all $delay_1 $transition-timing-function
    transition-delay: $delay_1

  .bottom-to-top-enter
    transform: translateY(100%)

  .mouse
    background-color: #fff
    border-radius: 100%
    height: vw(70px)
    left: 0
    opacity: 1
    pointer-events: none
    position: absolute
    top: 0
    transform: translate(-50%, -50%)
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out
    width: vw(70px)
    z-index: 100

    &.hide
      opacity: 0
      transform: scale(0.5) translate(-50%, -50%)
      transform-origin: center

    .arrow
      m-icon("arrow-back", 20)
      height: 100%
      margin: auto
      transform: rotate(0)
      transition: transform 0.5s ease-in-out

      &.left
        transform: rotate(180deg)

.swiper-carousel
  display: flex
  height: 100%
  position: relative
  transition-delay: 0

  .swiper-slide
    border-right: 2px solid #fff
    cursor: none
    height: 100%
    width: unset
    width: 80%

    .slide
      height: 100%
      position: relative

    .swiper-button-contaier
      align-items: center
      display: flex
      justify-content: center
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      width: 90%
      z-index: 10

    .button
      m-font-size(20px)
      background-color: #fff
      border-radius: 4px
      color: #000
      font-weight: 400
      padding: vw(19px) vh(24px)
      position: absolute
      text-transform: uppercase

    .img-wrapper
      background-position: center center
      background-repeat: no-repeat
      background-size: cover
      height: 100%
      width: 100%

.nav-button
  background-color: #fff
  border-radius: 50%
  height: vw(25px)
  padding: vw(5px)
  position: absolute
  top: 50%
  transform: translateY(-50%)
  transform-origin: top
  width: vw(25px)
  z-index: 5

  &.next
    right: vw(10px)

  &.prev
    left: vw(10px)
    transform: rotate(180deg) translateY(-50%)

  .icon
    m-icon("chevron", 25)

  &.bottom-to-top-enter-active,
  &.bottom-to-top-leave-active
    transition: all $delay_1 $transition-timing-function
    transition-delay: $delay_1

  &.bottom-to-top-enter
    opacity: 0
    transform: translateY(100vh)
</style>